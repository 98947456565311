//import { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import MarkdownText from "../markdown-text/markdown-text.component";
//import VideoComponent from "../video-component/video.component";

import "./home-hero.styles.scss";
import egr_award from '../../img/header/egr-award.png';
import sbc_award from '../../img/header/sbc-award.png';

import { ReactComponent as DotCluster } from "../../img/background-decor/home/hero-dots.svg";

const HomeHero = ({ headers, img, imgMobile, video, videoCover }) => {
  const navigate = useNavigate();
  //const isMuted = useRef(true)
  //const videoRef = useRef(null)
  

  //useEffect(() => {
  //  videoRef.current.defaultMuted = true;
  //}, [])

  const htmlContent = `<video class="home-hero__img" autoPlay playsinline muted loop>
  <source src="https://pragmatic-website.s3.eu-west-3.amazonaws.com/loop_fade_right_full_3ba3af030d.mp4" type="video/mp4" />
</video>`;

  return (
    <section className="home-hero">
      <div className="home-hero__image-container">
        <div className="home-hero__image-inner">
          <div className="home-hero__headline">
            <h1 className="heading-1 margin-small-bt home-hero__header">
              <MarkdownText
                className="platform-front__text"
                content={headers.header}
              ></MarkdownText>
            </h1>
            <button
              className="btn btn-big home-hero__button"
              data-btn="Contact Us"
              onClick={() => navigate("/contact-us")}
            ></button>
          </div>
          <div className="home-hero__award">
            <img src={egr_award} alt="" />
            <img src={sbc_award} alt="" />
          </div>
          <DotCluster className="home-hero__dots" />

          {videoCover && videoCover.data ? (
            <img
              className="home-hero__img"
              src={`${process.env.REACT_APP_CMS_MEDIA}${videoCover.data.attributes.url}`}
              alt={`${videoCover.data.attributes.alternativeText}`}
            />
          ) : null}

          {video && video.data ? (
            <>
              {/*<VideoComponent src={process.env.REACT_APP_CMS_MEDIA + video.data.attributes.url} />*/}
              {/*<video className="home-hero__img"
                ref={videoRef}
                onCanPlay={ (e) => { e.target.muted=true; e.target.play() } }
                autoPlay
                playsinline
                muted={isMuted.current}
                loop>
                <source
                  src={`${process.env.REACT_APP_CMS_MEDIA}${video.data.attributes.url}`}
                  type="video/mp4"
                />
              </video>*/}
              <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
            </>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
